@import '../../styles/common';

.rs-divider {
  background-color: var(--rs-divider-border);

  &-vertical {
    display: inline-block;
    height: 1em;
    width: 1px;
    vertical-align: middle;
    margin: 0 @divider-vertical-margin;
  }

  &-horizontal {
    height: 1px;
    margin: @divider-horizontal-margin 0;
  }

  &-horizontal&-with-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    margin: @divider-with-text-horizontal-margin 0;

    &::before,
    &::after {
      content: '';
      flex: 1 1 auto;
      border-top: 1px solid var(--rs-divider-border);
    }
  }

  &-inner-text {
    padding: 0 @divider-text-space;
    flex: 0 0 auto;
  }
}
