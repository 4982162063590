@import '../../styles/common';

//
// Tags
// --------------------------------------------------

.rs-tag {
  display: inline-block;
  background-color: var(--rs-tag-bg);
  color: var(--rs-text-primary);
  border-radius: 6px;
  line-height: 20px;

  &-closable {
    position: relative;
  }

  &-icon-close {
    position: absolute;
    font-size: 12px;
  }

  &-default &-icon-close:hover {
    color: var(--rs-tag-close);
  }

  &-group {
    margin: (-1 * @tag-gap) 0 0 (-1 * @tag-gap);
  }

  &-group > & {
    margin-top: @tag-gap;
    margin-left: @tag-gap;
  }

  &-lg {
    padding: 5px 10px;
    font-size: 14px;

    .rs-tag-icon-close {
      padding: 9px 8px;
    }
  }

  &-closable&-lg {
    padding-right: 27px;
  }

  &-md {
    padding: 2px 8px;
    font-size: 12px;

    .rs-tag-icon-close {
      padding: 6px 8px;
    }
  }

  &-closable&-md {
    padding-right: 25px;
  }

  &-sm {
    padding: 0 8px;
    font-size: 12px;

    .rs-tag-icon-close {
      padding: 4px 5px;
    }
  }

  &-closable&-sm {
    padding-right: 25px;
  }
}

.rs-tag + .rs-tag {
  margin-left: @tag-gap;
}

// Colorful tags
each(@spectrum, .(@color) {
  .rs-tag-@{color} {
    background-color: var(~'--rs-@{color}-500');
    color: #ffffff;
  }
});
