@import '../../styles/common';

//
// Breadcrumbs
// --------------------------------------------------

.rs-breadcrumb {
  margin-bottom: @line-height-computed;
  font-size: @breadcrumb-font-size;
  color: var(--rs-text-secondary);

  // If breadcrumbs has a ci
  &-item {
    transition: color 0.3s linear;

    &:focus {
      .tab-focus();
    }
  }

  // Separator has gap with text
  &-separator {
    margin: 0 @breadcrumb-separator-margin;
  }
}
