@import '../../styles/common';
@import 'mixin';

.rs-checkbox {
  .radio-checkbox-common();

  [type='checkbox']:focus-visible ~ .rs-checkbox-inner::before {
    box-shadow: var(--rs-state-focus-shadow);
  }
}

.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: (@checkbox-sense-width*2 + @checkbox-width-height);
  min-height: (@checkbox-sense-width*2 + @checkbox-width-height);
  line-height: 1;
  position: relative;
}

/* rtl:begin:ignore */
.rs-checkbox-wrapper {
  &::before,
  &::after,
  .rs-checkbox-inner::before,
  .rs-checkbox-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  // Check mark
  .rs-checkbox-inner::after {
    opacity: 0;
    transform: rotate(45deg) scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .high-contrast-mode({
      transition: none;
    });

    .rs-checkbox-checked &,
    .rs-checkbox-indeterminate & {
      border: solid var(--rs-checkbox-icon);
      width: 6px;
      height: 9px;
      margin-top: 2px;
      margin-left: 5px;
      opacity: 1;
    }

    .rs-checkbox-checked & {
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }

    .rs-checkbox-indeterminate & {
      border-width: 0 0 2px;
      transform: rotate(0deg) scale(1);
      width: 10px;
      margin-top: 0;
      margin-left: 3px;
    }
  }
}

/* rtl:end:ignore */

/* stylelint-disable-next-line */ // For RTL Scope
.rs-checkbox-wrapper {
  position: absolute;
  width: @checkbox-width-height;
  height: @checkbox-width-height;
  display: inline-block;
  left: @checkbox-sense-width;
  top: @checkbox-sense-width;

  [type='checkbox'] {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &::before,
  .rs-checkbox-inner::before,
  .rs-checkbox-inner::after {
    width: @checkbox-width-height;
    height: @checkbox-width-height;
  }

  // Ripple effect area
  &::before {
    .checkbox-inner-wrap(var(--rs-checkbox-checked-bg));

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .high-contrast-mode({
      transition: none;
    });

    .rs-checkbox-checked & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sense area(Can be clicked)
  &::after {
    top: -@checkbox-sense-width;
    right: -@checkbox-sense-width;
    bottom: -@checkbox-sense-width;
    left: -@checkbox-sense-width;
  }

  // Out border
  .rs-checkbox-inner::before {
    .checkbox-inner-wrap(var(--rs-checkbox-border));

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .high-contrast-mode({
      transition: none;
    });

    label:hover & {
      border-color: var(--rs-checkbox-checked-bg);
    }

    .rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) & {
      border-color: var(--rs-checkbox-disabled-bg);
      background-color: var(--rs-checkbox-disabled-bg);
    }

    .rs-checkbox-checked &,
    .rs-checkbox-indeterminate & {
      border-color: var(--rs-checkbox-checked-bg);
      background-color: var(--rs-checkbox-checked-bg);
    }

    .rs-checkbox-disabled.rs-checkbox-checked &,
    .rs-checkbox-disabled.rs-checkbox-indeterminate & {
      opacity: 0.3;

      .high-contrast-mode({
        opacity: 0.5;
      });
    }
  }
}
