@import '../../styles/common';

// Close button
.rs-btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: @message-close-btn-padding;
  font-size: @message-close-btn-size;
  line-height: 1;
  border: none;
  outline: none !important;
  cursor: pointer;

  .rs-icon {
    vertical-align: bottom;
  }
}
