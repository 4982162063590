@import '../../styles/common';

//
// PanelGroup
// --------------------------------------------------

.rs-panel-group {
  // The first panel and the last panel in a panel group need set border-radius.
  border-radius: 6px;
  overflow: hidden;

  &-bordered {
    border: 1px solid var(--rs-border-primary);
  }

  // Panel in Panel group haven't border.
  > .rs-panel {
    border: none;

    // Draw a split line for panel
    & + .rs-panel {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        border-top: 1px solid var(--rs-border-primary);
        left: 20px;
        right: 20px;
      }
    }
  }
}
