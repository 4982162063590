@import '../../styles/common';

// FormControlLabel

.rs-form-control-label {
  margin-bottom: @form-control-label-margin-bottom;
}

.rs-form-plaintext .rs-form-control-label {
  color: var(--rs-text-secondary);
}
