// Messages

.message-variant(@background; @header-color; @text-color; @icon-color) {
  background-color: @background;

  .rs-message-header {
    color: @header-color;
  }

  .rs-message-body {
    color: @text-color;
  }

  .rs-message-icon-wrapper > .rs-icon,
  .rs-btn-close {
    color: @icon-color;
  }
}
