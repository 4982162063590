@import '../../styles/common';

//
// Slider
// --------------------------------------------------
.rs-slider {
  position: relative;

  .rs-tooltip {
    display: none;

    &.rs-tooltip-placement-top .rs-tooltip::after {
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  &-disabled {
    opacity: 0.3;
    cursor: not-allowed;

    .rs-slider-bar,
    .rs-slider-handle::before {
      cursor: not-allowed;
    }
  }

  &-with-mark:not(&-vertical) {
    //White space for mark
    margin-bottom: (@line-height-computed + @slider-mark-margin-top);
  }
}

// Bar
.rs-slider-bar {
  height: @slider-bar-side-length;
  border-radius: (@slider-bar-side-length / 2);
  background-color: var(--rs-slider-bar);
  transition: background-color ease-in-out 0.3s;
  cursor: pointer;

  .rs-slider:hover & {
    background-color: var(--rs-slider-hover-bar);
  }

  .rs-slider-vertical & {
    height: 100%;
    width: @slider-bar-side-length;
  }
}

//
.rs-slider-handle {
  position: absolute;
  top: -50%;

  &::before {
    content: '';
    position: absolute;
    width: @slider-handle-diameter;
    height: @slider-handle-diameter;
    border-radius: 50%;
    border: @slider-handle-border-width solid var(--rs-slider-thumb-border);
    background-color: var(--rs-slider-thumb-bg);
    margin-left: -(@slider-handle-diameter / 2);
    cursor: pointer;
    /* stylelint-disable */ //Formatted by prettier
    transition: box-shadow @slider-handle-transition, background-color @slider-handle-transition,
      transform @slider-handle-transition;
    /* stylelint-enable */
  }

  &:hover::before,
  &:focus::before {
    box-shadow: var(--rs-slider-thumb-hover-shadow);
  }

  &:active,
  &.active {
    &::before {
      transform: scale(1.2);
    }
  }

  .rs-slider-vertical & {
    top: unset;

    &::before {
      left: ((@slider-handle-diameter - @slider-bar-side-length)/2);
      margin-top: (-@slider-handle-diameter / 2);
    }
  }
}

.rs-slider-handle:hover .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
  display: block;
  opacity: 1;
  top: -30px;

  .rs-slider-vertical & {
    top: -33px;
    // Make sure tooltip align center.
    margin-left: 3px;
  }
}

// Mark
.rs-slider-mark {
  position: absolute;
  top: (@slider-bar-side-length + @slider-mark-margin-top);
  left: -2px;
  white-space: nowrap;

  &-content {
    margin-left: -50%;
  }
}

.rs-slider-mark-last {
  left: auto;
  right: -2px;

  .rs-slider-mark-content {
    margin-left: 50%;
  }
}

// Graduator
.rs-slider-graduator {
  width: 100%;

  ol,
  li {
    list-style: none;
  }

  > ol {
    display: flex;
    padding-left: 0;
    width: 100%;

    > li {
      flex: 1 1 1%;
      position: relative;

      &:last-child::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: @slider-calibration-diameter;
        height: @slider-calibration-diameter;
        border-radius: 50%;
        background-color: var(--rs-slider-thumb-bg);
        box-sizing: border-box;
        border: @slider-calibration-border-width solid var(--rs-slider-bar);
        margin-left: -4px;
        top: -1px;

        // Vertical styles
        .rs-slider-vertical & {
          top: unset;
          bottom: -4px;
          margin-left: -1px;
        }
      }

      &:last-child::after {
        right: -4px;

        // Vertical styles
        .rs-slider-vertical & {
          left: 0;
          bottom: unset;
          top: -4px;
        }
      }

      &.rs-slider-pass::before {
        border-color: var(--rs-slider-progress);
      }

      &.rs-slider-active::before {
        visibility: hidden;
      }
    }
  }

  // Vertical styles
  .rs-slider-vertical & {
    display: block;
    height: 100%;

    > ol {
      width: @slider-bar-side-length;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      padding: 0;

      & > li {
        display: block;
        padding: 0;
      }
    }
  }
}

// Progress bar
.rs-slider-progress-bar {
  position: absolute;
  height: @slider-bar-side-length;
  border-radius: (@slider-bar-side-length / 2) 0 0 (@slider-bar-side-length / 2);
  background-color: var(--rs-slider-progress);

  .rs-slider-vertical & {
    width: @slider-bar-side-length;
    border-radius: 0 0 (@slider-bar-side-length / 2) (@slider-bar-side-length / 2);
  }
}

// Vertical
.rs-slider-vertical {
  height: 100%;

  // Reset all setting
  .rs-slider-mark {
    top: unset;
    bottom: -8px;
    left: (@slider-bar-side-length + @slider-mark-margin-top);

    &-content {
      margin-left: auto;
    }
  }

  .rs-slider-mark-last {
    bottom: unset;
    top: -8px;
  }
}
