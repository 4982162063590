@import 'variables';

//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
  color: @headings-color;
  margin: 0;

  small {
    font-weight: normal;
    line-height: 1;
    color: var(--rs-text-disabled);
  }
}

h1 {
  font-size: @font-size-h1;
  line-height: @line-height-h1;
}

h2 {
  font-size: @font-size-h2;
  line-height: @line-height-h2;
}

h3 {
  font-size: @font-size-h3;
  line-height: @line-height-h3;
}

h4 {
  font-size: @font-size-h4;
  line-height: @line-height-h4;
}

h5 {
  font-size: @font-size-h5;
  line-height: @line-height-h5;
}

h6 {
  font-size: @font-size-h6;
  line-height: @line-height-h6;
}

// Body text
// -------------------------

p {
  margin: 0;

  // Multiple <p/> need set margin
  & + p {
    margin-top: @padding-y;
  }
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small {
  font-size: floor((100% * @font-size-small / @font-size-base));
}

mark {
  background-color: var(--rs-bg-warning);
  padding: 0.2em;
  font-weight: bolder;
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);

  ul,
  ol {
    margin-bottom: 0;
  }
}

// Description Lists
dl {
  margin-top: 0; // Remove browser default
  margin-bottom: (@line-height-computed / 2);
}

dt,
dd {
  line-height: @line-height-base;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0; // Undo browser default
  margin-bottom: (@line-height-computed / 2);
}

// font-face
@font-face {
  font-family: Apple-System;
  src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
}
