.input-touchspin(@size) {
  @size-name: ~'input-height-@{size}';
  @height: ((@@size-name - 2px) / 2);

  height: @height;

  > .rs-icon {
    height: @height;
  }
}
