@import '../../styles/common';
@import 'animation';

//
// toaster
// --------------------------------------------------

.rs-toast {
  // Reset pointer events ,to overwrite line:16
  pointer-events: auto;
}

.rs-toast-container {
  position: fixed;
  z-index: @zindex-notification;
  // Make sure the mouse can penetrate through the gap area.
  pointer-events: none;
  display: flex;
  flex-direction: column;

  // Notification has box-shadow on rs-notification-content
  .rs-toast:not(.rs-notification) {
    box-shadow: var(--rs-shadow-overlay);
  }

  // Animations
  .rs-toast-fade-entered,
  .rs-toast-fade-exiting {
    animation-fill-mode: forwards;
  }

  .rs-toast-fade-entered {
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  }

  .rs-toast-fade-exited,
  .rs-toast-fade-entering {
    opacity: 0;
  }

  // The same to @keyframe notificationMoveOut  100%.
  .rs-toast-fade-exited {
    transform-origin: 0 0;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }

  &-top-center,
  &-bottom-center {
    align-items: center;
    width: 100%;

    .rs-toast-fade-entered {
      animation-name: notificationMoveIn;
    }
  }

  &-bottom-start,
  &-top-start {
    align-items: flex-start;
    left: @toast-spacing;

    .rs-toast-fade-entered {
      margin-left: auto;
      animation-name: notificationMoveInLeft;
    }
  }

  &-bottom-end,
  &-top-end {
    align-items: flex-end;
    right: @toast-spacing;

    .rs-toast-fade-entered {
      margin-left: auto;
      animation-name: notificationMoveInRight;
    }
  }

  &-top-center,
  &-top-start,
  &-top-end {
    top: @toast-spacing;
  }

  &-bottom-center,
  &-bottom-start,
  &-bottom-end {
    bottom: @toast-spacing;
  }

  .rs-toast-fade-exiting {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
    animation-name: notificationMoveOut;
  }
}
