// Create grid for specific class
.make-grid(@size) {
  each(range(24), {
    .rs-col-@{size}-@{value} {
      width: percentage((@value / 24));
    }
    .rs-col-@{size}-pull-@{value} {
      right: percentage((@value / 24));
    }
    .rs-col-@{size}-push-@{value} {
      left: percentage((@value / 24));
    }

    .rs-col-@{size}-offset-@{value} {
      margin-left: percentage((@value / 24));
    }
  });
  .rs-col-@{size}-pull-0 {
    right: auto;
  }
  .rs-col-@{size}-push-0 {
    left: auto;
  }
}

.hidden(@size) {
  .rs-hidden-@{size} {
    display: none;
  }
}
