@import '../../styles/common';

.rs-checkbox-group {
  display: flex;
  flex-direction: column;
}

.rs-checkbox-group-inline {
  flex-direction: row;
  margin-left: -1 * @radio-sense-width;
}
