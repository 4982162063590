.avatar-size(@side-length,@font-size) {
  width: @side-length;
  height: @side-length;
  font-size: @font-size;

  & > .rs-icon {
    @icon-font-size: ((@side-length - @font-size)/2 + @font-size);

    font-size: @icon-font-size;
    height: @icon-font-size;
  }

  & > .rs-avatar-image {
    width: @side-length;
    height: @side-length;
    line-height: @side-length;
  }
}

.avatar-lg() {
  .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
}

.avatar-md() {
  .avatar-size(@avatar-size, @avatar-font-size);
}

.avatar-sm() {
  .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
}

.avatar-xs() {
  .avatar-size(@avatar-size-xs, @avatar-font-size-xs);
}
