@import '../../styles/common';

//
// List
// --------------------------------------------------

.rs-list {
  position: relative;
  // border-top and border-bottom
  box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
  overflow-x: hidden;
  overflow-y: auto;

  &-bordered {
    border-radius: @border-radius;
    box-shadow: 0 0 0 1px var(--rs-list-border);
  }

  &-hover .rs-list-item:hover {
    background-color: var(--rs-list-hover-bg);
  }

  &-sortable .rs-list-item {
    cursor: move; // fallback on IE 11
    cursor: grab;

    &-disabled,
    &-disabled:active {
      cursor: not-allowed;
    }
  }
}

// default list item ( axis-y )
.rs-list-item {
  position: relative;

  &-bordered {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-md {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &-sm {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* rtl:begin:ignore */
/* stylelint-disable-next-line */ // For RTL Scope
.rs-list-item {
  background-color: var(--rs-list-bg);
  box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);

  &-helper {
    position: absolute;
    background-color: var(--rs-list-bg);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - 2px);
    border-radius: @border-radius;
    top: 0;
    left: 0;
    z-index: @zindex-list-helper;
    border: none;
    cursor: move;

    // Increase z-index when modal opened.
    .rs-modal-open & {
      z-index: (@zindex-modal + @zindex-list-helper);
    }

    // Increase z-index when drawer opened.
    .rs-drawer-open & {
      z-index: (@zindex-drawer + @zindex-list-helper);
    }
  }

  &-holder {
    background-color: var(--rs-list-placeholder-bg);
    visibility: hidden;

    &::after {
      content: '';
      position: absolute;
      visibility: visible;
      top: @list-border-width;
      left: @list-border-width;
      width: calc(100% - 2 * @list-border-width);
      height: calc(100% - 2 * @list-border-width);
      border: @list-border-width var(--rs-list-placeholder-border) dashed;
    }
  }
}

/* rtl:end:ignore */
