@import '../../styles/common';

.rs-avatar-group {
  display: flex;
  align-items: flex-end;
  &-stack {
    .rs-avatar {
      box-sizing: content-box;
      margin-right: -10px;
      transition: margin 0.15s;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        margin-right: 0;
      }
    }
  }
}
