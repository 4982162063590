@import '../../styles/common';

// Rate
// ----------------------

.rs-rate {
  position: relative;
  display: inline-flex;
  color: var(--rs-rate-symbol-checked);
  list-style: none;
  vertical-align: middle;
  margin: 0;
  padding: 0;

  &-character {
    position: relative;
    margin-right: 10px;
    padding: 3px 0;
    cursor: pointer;
    outline: none;
    user-select: none;

    &-before {
      position: absolute;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
    }

    &-after {
      color: var(--rs-rate-symbol);
      filter: grayscale(1);

      .high-contrast-mode({
        > .rs-icon {
          fill: none;
          stroke: currentColor;
        }
      });
    }

    &-before,
    &-after {
      display: flex;
      z-index: @zindex-rate-character-before;

      > .rs-icon {
        font-size: inherit;
      }
    }

    &-half &-before {
      opacity: 1;
      filter: none;
    }

    &-full &-after {
      color: inherit;
      filter: none;

      > .rs-icon {
        fill: currentColor;
      }
    }

    &-vertical {
      width: 100%;
      height: 50%;
      flex-direction: column-reverse;
      bottom: 3px;
    }

    .rs-icon {
      position: absolute;
      height: 1em;
      width: 1em;
    }
  }

  &:not(&-readonly)&:not(&-disabled) &-character {
    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }
  }

  &-disabled {
    opacity: 0.3;
    outline: none;
  }

  &-disabled &-character {
    cursor: not-allowed;
  }

  &-readonly &-character {
    cursor: default;
    pointer-events: none;
  }

  // Size variants
  @sizes: lg, md, sm, xs;

  each(@sizes, .(@size) {
    @font-size-var-name: ~'rate-@{size}-font-size';
    @font-size: @@font-size-var-name;

    &-@{size} {
      font-size: @font-size;

      .rs-rate-character {
        width: @font-size;
        height: (@font-size + 6);
      }
    }
  });

  // Color variants
  each(@spectrum, .(@color) {
    &-@{color} {
      color: var(~'--rs-@{color}-500');
    }
  });
}
