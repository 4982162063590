@keyframes shakeHead {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  25%,
  75% {
    transform: translate3d(-10px, 0, 0);
  }

  50% {
    transform: translate3d(10px, 0, 0);
  }
}
