@import '../../styles/mixins/color-modes';

.default-input() {
  display: block;
  width: 100%;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid var(--rs-border-primary);
  transition: @input-transition;
  border-radius: @border-radius;
  .input-md();

  .high-contrast-mode({
    transition: none;
  });

  // Placeholder
  &::placeholder {
    color: var(--rs-text-secondary);
  }

  &:focus,
  &:hover:not(:disabled) {
    border-color: var(--rs-input-focus-border);
    outline: 0;
  }

  &:focus {
    box-shadow: var(--rs-state-focus-shadow);
  }

  &:disabled {
    background-color: var(--rs-input-disabled-bg);
    color: var(--rs-text-disabled);
  }
}

.input-size( @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius;) {
  @ie-height: (@padding-vertical * 2 + @font-size * @line-height);

  padding: (@padding-vertical - @input-border-width) (@padding-horizontal - @input-border-width);
  font-size: @font-size;
  line-height: @line-height;
  // You must declared height in IE
  .ie11-height(@ie-height);

  textarea& {
    height: auto;
  }
}

.input-lg() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-y-lg; @input-padding-x; @font-size-large; @line-height-large; @input-border-radius
  );
  /* stylelint-enable */
}

.input-md() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
          @padding-y; @input-padding-x; @font-size-base; @line-height-base; @input-border-radius
  );
  /* stylelint-enable */
}

.input-sm() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-y-sm; @input-padding-x; @font-size-base; @line-height-base; @input-border-radius
  );
  /* stylelint-enable */
}

.input-xs() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-y-xs; @input-padding-x; @font-size-extra-small; @line-height-extra-small; @input-border-radius
  );
  /* stylelint-enable */
}

.reset-input-group-addon-size(@size-name) {
  @padding-horizontal-name: ~'padding-@{size-name}-horizontal';

  @width: (@@padding-horizontal-name * 2 - @input-border-width*2 + @font-size-base);
  @padding-horizontal: ((@width - @font-size-base)/2);

  min-width: @width;
  padding-left: @padding-horizontal;
  padding-right: @padding-horizontal;
}

.reset-inside-input-group-btn-size(@size-name) {
  @height: ~'input-height-@{size-name}';
  @vertical: ~'padding-@{size-name}-vertical';
  @horizontal: ~'padding-@{size-name}-horizontal';
  @font-size: ~'font-size-@{size-name}';
  @line-height: ~'line-height-@{size-name}';

  height: @@height - @input-border-width*2;
  padding: (@@vertical - @input-border-width) (@@horizontal - @input-border-width);
  border-radius: 0 @border-radius @border-radius 0;

  > .rs-icon {
    // Extend font-size from parent.
    font-size: @@font-size;
    line-height: @@line-height;
  }
}

.reset-input-group-children-size(@size-name) {
  @height: ~'input-height-@{size-name}';
  @useable-height: (@@height - 2px);
  @vertical: ~'padding-@{size-name}-vertical';
  @add-on-padding: ~'input-group-padding-for-add-on-@{size-name}';

  &.rs-input-group-inside > .rs-input {
    padding-right: @@add-on-padding;
  }

  &.rs-input-group {
    > .rs-input {
      height: @useable-height;
    }

    > .rs-input-group-addon {
      height: @useable-height;

      > .rs-icon {
        font-size: inherit;
      }
    }
  }
}
