@import '../../styles/variables';
@import '../../styles/mixins/hacks';
@import '../../styles/mixins/color-modes';

// Draw a triangle towards given direction
// .tooltip-arrow(@direction, @size, @color)
//
// @direction: top | right | bottom | left
// @size:      Size (height) of the triangle
// @color:     Filling color of the triangle

.tooltip-arrow(top, @size, @color) {
  top: -@size;
  // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
  .ie11-edge-direction(top, -(@size+1px));

  margin-left: -@size;
  border-width: 0 @size @size;
  border-bottom-color: @color;
}

.tooltip-arrow(bottom, @size, @color) {
  bottom: -@size;
  // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
  .ie11-edge-direction(bottom, -(@size+1px));

  margin-left: -@size;
  border-width: @size @size 0;
  border-top-color: @color;
}

/* rtl:begin:ignore */
.tooltip-arrow(right, @size, @color) {
  right: -@size;
  // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
  .ie11-edge-direction(right, -(@size+1px));

  margin-top: -@size;
  border-width: @size 0 @size @size;
  border-left-color: @color;
}

.tooltip-arrow(left, @size, @color) {
  left: -@size;
  // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
  .ie11-edge-direction(left, -(@size+1px));

  margin-top: -@size;
  border-width: @size @size @size 0;
  border-right-color: @color;
}

/* rtl:end:ignore */

// Append tooltip's arrow to tooltip
// @direction: The arrow's pointing direction. It's usually opposite of tooltip's placement
//             e.g. tooltip[placement="top"] has an arrow[direction="bottom"]
// @size:      The arrow's size (height)
// @bg:        The arrow's background color, usually equal to tooltip's background
// @border:    The arrow's border color, usually equal to tooltip's border (which only take effect
//             in high-contrast theme)
.with-tooltip-arrow(@direction, @size: @tooltip-arrow-width, @bg: var(--rs-tooltip-bg), @border: var(--rs-tooltip-border)) {
  &::after {
    .tooltip-arrow(@direction, @size, @bg);
  }

  .high-contrast-mode({
    &::before {
      .tooltip-arrow(@direction, @size + 1px, @border);
    }
  });
}
