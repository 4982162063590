// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left: floor((@gutter / 2));
  padding-right: ceil((@gutter / 2));
  .clearfix();
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
  margin-left: ceil((@gutter / -2));
  margin-right: floor((@gutter / -2));
  .clearfix();
}
