.checkbox-inner-wrap(@border-color) {
  border: 1px solid @border-color;
  background-color: @checkbox-bg;
  border-radius: @checkbox-border-radius;
}

// Radio and Checkbox common styles
.radio-checkbox-common() {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  // These classes are used on elements with <label> descendants
  &-disabled {
    label {
      cursor: @cursor-disabled;
    }
  }

  label {
    line-height: unit((@checkbox-width-height / @font-size-base));
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }

  &-inner::before {
    border-color: var(--rs-checkbox-checked-bg);
  }

  &&-disabled label {
    cursor: not-allowed;
  }

  // Disabled text styles.
  &-disabled > &-checker > label {
    color: var(--rs-text-disabled);
  }

  // Radios and checkboxes on same line
  &-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-left: @radio-sense-width; // space out consecutive inline controls

    .rs-plaintext &:first-child {
      margin-left: 0;
    }
  }
}
