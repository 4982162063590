@import '../../styles/common';
@import '../../Form/styles/index';

//
// FormGroup
// --------------------------------------------------

.rs-form-group {
  .rs-form:not(.rs-form-inline) &:not(:last-child) {
    margin-bottom: @form-group-margin-bottom;
  }

  .rs-input {
    display: inline-block;
  }

  // Fixed inline element will produce a whitespace
  textarea.rs-input {
    vertical-align: bottom;
  }
}

// Vertical forms
.rs-form-vertical .rs-form-group {
  .rs-form-control-label {
    display: block;
  }

  .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    padding-top: 4px;
  }
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.rs-form-horizontal .rs-form-group {
  .clearfix();

  .rs-form-control-label {
    float: left;
    display: inline-block;
    //Sometimes help info is more than one line，so height can't set a fixed value
    min-height: @line-height-computed;
    line-height: @line-height-base;
    font-size: @font-size-base;
    padding: @padding-y 0;
    width: @form-control-label-width;
    margin-right: @form-control-label-margin-right;
    text-align: right;
  }

  .rs-btn-toolbar {
    padding-left: @form-control-label-width + @form-control-label-margin-right;
  }
}

// Inline
.rs-form-inline .rs-form-group {
  display: inline-block;
}
