@import '../../styles/common';
@import '../../Form/styles/mixin';
@import '../../Input/styles/index';
@import '../../Picker/styles/index';

.rs-picker-input {
  position: relative;
  border: 1px solid var(--rs-border-primary);
  border-radius: @border-radius;
  transition: @picker-transition;
  background-color: var(--rs-input-bg);

  .rs-picker-toggle {
    border: none !important;
    background: transparent !important;
    height: @input-picker-toggle-content-height;
    cursor: text;

    &-clean,
    &-caret {
      top: @padding-y - @picker-default-toggle-border-width;
    }

    .rs-ripple-pond {
      display: none;
    }
  }

  &:not(.rs-picker-disabled) .rs-picker-toggle {
    position: absolute !important;
  }

  .input-picker-input-sizes();
}

.rs-picker-tag-wrapper {
  margin-right: @dropdown-toggle-padding-right;
  min-height: 34px;

  .rs-picker-toggle.rs-btn-lg ~ & {
    min-height: 40px;
  }

  .rs-picker-toggle.rs-btn-sm ~ & {
    min-height: 28px;
  }

  .rs-picker-toggle.rs-btn-xs ~ & {
    min-height: 22px;
  }

  .rs-picker-cleanable & {
    margin-right: (@dropdown-toggle-padding-right + @picker-toggle-clean-width);
  }
}

.rs-picker-search {
  border: none;
  width: 100%;

  &-input {
    .input-md();

    .rs-picker-tag & {
      font-size: @font-size-base;
      line-height: @line-height-base;
      padding: @padding-y-xs @input-padding-x @padding-y-xs @padding-x -
        @picker-default-toggle-border-width;

      > input {
        background: none;
        outline: none;
        border: none;
        width: 100%;
      }
    }

    background: none;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    padding-right: 0;

    .rs-picker-focused & {
      z-index: @zindex-picker-input;
    }
  }
}
