& {
  // Gray levels
  --rs-gray-50: @B050;
  --rs-gray-100: @B100;
  --rs-gray-200: @B200;
  --rs-gray-300: @B300;
  --rs-gray-400: @B400;
  --rs-gray-500: @B500;
  --rs-gray-600: @B600;
  --rs-gray-700: @B700;
  --rs-gray-800: @B800;
  --rs-gray-900: @B900;

  // Highlight levels
  --rs-primary-50: @H050;
  --rs-primary-100: @H100;
  --rs-primary-200: @H200;
  --rs-primary-300: @H300;
  --rs-primary-400: @H400;
  --rs-primary-500: @H500;
  --rs-primary-600: @H600;
  --rs-primary-700: @H700;
  --rs-primary-800: @H800;
  --rs-primary-900: @H900;

  // Spectrum levels
  each(@spectrum, .(@color-name) {
    @color: @@color-name;
    @color-name-50: ~'@{color-name}-50';
    --rs-@{color-name}-50: @@color-name-50;

    each(range(9), {
      @level: @{index}00;
      @color-name-level: ~'@{color-name}-@{level}';
      --rs-@{color-name}-@{level}: @@color-name-level;
    });
  });

  // Stateful colors
  --rs-state-success: @green;
  --rs-state-info: @blue;
  --rs-state-warning: @yellow;
  --rs-state-error: @red;
}
