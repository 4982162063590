@import '../../styles/common';
@import '../../Cascader/styles/index';
@import '../../Picker/styles/index';
@import '../../Checkbox/styles/index';

// MultiCascader
// ----------------------

.rs-picker-cascader {
  &-menu .rs-check-item .rs-checkbox-checker > label {
    padding-right: 26px; // 12px + 8px + 6px
  }

  &-menu .rs-check-item.rs-check-item-focus,
  &-menu .rs-check-item.rs-checkbox-checked {
    .rs-checkbox-checker > label {
      font-weight: bold;
    }
  }

  &-menu-column&-menu-column-uncheckable {
    .rs-check-item .rs-checkbox-checker > label {
      padding-left: @picker-item-content-padding-horizontal;
    }
  }
}

.rs-picker-multi-cascader-menu .rs-picker-cascader-col {
  padding: 0;
}

.rs-picker-multi-cascader-menu .rs-picker-cascader-row {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
