@import '../../styles/common';
@import 'mixin';
@import 'animation';

// Slide
.rs-anim-slide-out {
  .animation-common(cubic-bezier(0.4, 0, 1, 1)); //Fast out
}

.rs-anim-slide-in {
  .animation-common(ease-in-out);
}

.rs-anim-right {
  .slide-animation-setting(slideInRight, slideOutRight);
}

.rs-anim-left {
  .slide-animation-setting(slideInLeft, slideOutLeft);
}

.rs-anim-top {
  .slide-animation-setting(slideInTop, slideOutTop);
}

.rs-anim-bottom {
  .slide-animation-setting(slideInBottom, slideOutBottom);
}

// Bounce
.rs-anim-bounce-in {
  animation-name: bounceIn;
  .animation-common(cubic-bezier(0.68, -0.55, 0.27, 1.55)); // Ease in out

  @keyframes bounceIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.rs-anim-bounce-out {
  animation-name: bounceOut;
  .animation-common(cubic-bezier(0.4, 0, 1, 1)); //Fast out

  @keyframes bounceOut {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
}

.rs-anim-fade {
  opacity: 0;
  transition: opacity 0.15s linear;
  pointer-events: none;

  &.rs-anim-in {
    opacity: 1;
    pointer-events: unset;
  }
}

.rs-anim-collapse {
  display: none;

  &.rs-anim-in {
    display: block;
  }

  tr&.rs-anim-in {
    display: table-row;
  }

  tbody&.rs-anim-in {
    display: table-row-group;
  }
}

.rs-anim-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
}
