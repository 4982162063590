@import '../../styles/common';
@import '../../toaster/styles/animation';
@import 'mixin';

//
// Notifications
// --------------------------------------------------

// Base styles
// -------------------------
.rs-notification {
  // Recovery mouse event.
  pointer-events: auto;
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  animation-fill-mode: forwards;
  border-radius: @notify-border-radius;
  background: var(--rs-bg-overlay);
  box-shadow: @notify-box-shadow;
  overflow: hidden;

  // Multiple notification should have spacing
  // Hiding notification haven't spacing.
  &:not(.@{toast-ns}-fade-exited) ~ .rs-notification {
    margin-top: 10px;
  }

  .high-contrast-mode({
    border: 1px solid var(--rs-border-primary);
  });

  // Notification content
  &-content {
    padding: @notify-padding;
    max-width: @notify-max-width;
  }

  // Title
  &-title {
    .ellipsis();

    color: var(--rs-text-heading);
    font-size: @notify-title-font-size;
    line-height: unit((@notify-title-icon-size / @notify-title-font-size));

    > p {
      margin: 0;
    }

    .rs-icon {
      font-size: @notify-title-icon-size;
      width: @notify-title-icon-size;
      line-height: 1;
      margin-right: @notify-title-icon-margin;
    }
  }

  &-title-with-icon {
    display: flex;
    align-items: center;
    .ellipsis();
  }

  // Description
  &-description {
    color: var(--rs-text-primary);
  }

  // Has title and description need to ajust distance
  &-title + &-description {
    margin-top: @notify-title-description-gap;
  }

  &&-hiding {
    animation: notificationMoveOut 0.3s ease-in forwards;

    & ~ .rs-notification {
      margin-top: 0;
    }
  }
}

// Alternate styles
// -------------------------
// Generate contextual modifier classes for colorizing the alert.

.rs-notification-success {
  .notification-variant(var(--rs-state-success));
}

.rs-notification-info {
  .notification-variant(var(--rs-state-info));
}

.rs-notification-warning {
  .notification-variant(var(--rs-state-warning));
}

.rs-notification-error {
  .notification-variant(var(--rs-state-error));
}
