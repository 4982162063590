@import '../../styles/common';
@import '../../Form/styles/mixin';
@import '../../Picker/styles/mixin';
@import '../../InputPicker/styles/index';

.rs-picker-tag {
  .rs-picker-toggle {
    // Make sure align with input
    left: 0;
    cursor: text;
  }

  &.rs-picker-disabled {
    cursor: not-allowed;

    .rs-picker-toggle {
      position: absolute;
    }
  }

  .rs-tag {
    max-width: ~'calc(100% - @{tag-picker-content-padding-horizontal})';
    vertical-align: top;
    .ellipsis-basic();
  }

  .rs-picker-search-input,
  .rs-tag {
    margin-top: @tag-picker-content-padding-vertical;
  }

  &.rs-picker-has-value .rs-picker-search-input {
    padding-left: @tag-picker-content-padding-horizontal;
  }

  .rs-tag,
  .rs-tag + .rs-tag {
    margin-left: @tag-picker-content-padding-horizontal;
  }

  .rs-picker-search {
    &,
    &-input,
    &-input > input {
      display: inline-block;
      width: auto;
      min-width: 14px;
    }

    &-input > input {
      padding: 0;
    }
  }

  .rs-picker-tag-wrapper {
    position: relative;
    // make sure tag in front of toggle
    z-index: @zindex-picker-toggle + 1;
    padding-bottom: @tag-picker-content-padding-vertical;
  }
}

.rs-plaintext .rs-tag {
  margin-left: 6px;
  margin-bottom: 6px;
}
