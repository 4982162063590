@import '../../styles/common';
@import 'animation';

.rs-form-error-message {
  position: absolute;
  padding: 3px 7px;
  background-color: var(--rs-form-errormessage-bg);
  border: 1px solid var(--rs-form-errormessage-border);
  border-radius: @border-radius;
  filter: ~'drop-shadow(0 0 6px rgba(0,0,0,.1))';
  z-index: @zindex-form-error-message;
  color: var(--rs-form-errormessage-text);
  font-size: @form-error-message-font-size;
  line-height: 20px;
  display: none;
  white-space: nowrap;

  // Used to provide relation for error message
  &-wrapper {
    position: absolute;
  }

  &-show {
    display: block;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
  }

  &-arrow {
    &::before,
    &::after {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }

  // Placement
  // == Bottom Left && Bottom Right && Top Left && Top Right
  &-placement-bottom-start,
  &-placement-bottom-end {
    bottom: 0;

    .rs-form-error-message {
      top: 0;

      &-show {
        animation-name: errorMessageSlideDownIn;
      }
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        border-width: 0 @form-error-message-triangle-width @form-error-message-triangle-width;
        border-bottom-color: var(--rs-form-errormessage-border);
        top: -1 * @form-error-message-triangle-width;
      }

      &::after {
        top: -1 * (@form-error-message-triangle-width - 1);
        border-bottom-color: var(--rs-form-errormessage-bg);
      }
    }
  }

  &-placement-top-start,
  &-placement-top-end {
    top: 0;

    .rs-form-error-message {
      bottom: 0;

      &-show {
        animation-name: errorMessageSlideUpIn;
      }
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        border-width: @form-error-message-triangle-width @form-error-message-triangle-width 0;
        border-top-color: var(--rs-form-errormessage-border);
        bottom: -1 * @form-error-message-triangle-width;
      }

      &::after {
        bottom: -1 * (@form-error-message-triangle-width - 1);
        border-top-color: var(--rs-form-errormessage-bg);
      }
    }
  }

  &-placement-bottom-start,
  &-placement-top-start {
    left: 0;

    .rs-form-error-message {
      left: 0;
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        left: @form-error-message-triangle-gap;
      }
    }
  }

  &-placement-bottom-end,
  &-placement-top-end {
    right: 0;

    .rs-form-error-message {
      right: 0;
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        right: @form-error-message-triangle-gap;
      }
    }
  }
}

/* rtl:begin:ignore */

// == Left Top && Left Bottom Right && Top && Right Bottom
/* stylelint-disable-next-line */ // For RTL Scope
.rs-form-error-message {
  &-placement-left-start,
  &-placement-left-end {
    left: 0;

    .rs-form-error-message {
      right: 0;

      &-show {
        animation-name: errorMessageSlideRightIn;
      }
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        border-width: @form-error-message-triangle-width 0 @form-error-message-triangle-width
          @form-error-message-triangle-width;
        border-left-color: var(--rs-form-errormessage-border);
        right: -1 * @form-error-message-triangle-width;
      }

      &::after {
        right: -1 * (@form-error-message-triangle-width - 1);
        border-left-color: var(--rs-form-errormessage-bg);
      }
    }
  }

  &-placement-right-start,
  &-placement-right-end {
    right: 0;

    .rs-form-error-message {
      left: 0;

      &-show {
        animation-name: errorMessageSlideLeftIn;
      }
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        border-width: @form-error-message-triangle-width @form-error-message-triangle-width
          @form-error-message-triangle-width 0;
        border-right-color: var(--rs-form-errormessage-border);
        left: -1 * @form-error-message-triangle-width;
      }

      &::after {
        left: -1 * (@form-error-message-triangle-width - 1);
        border-right-color: var(--rs-form-errormessage-bg);
      }
    }
  }

  &-placement-left-start,
  &-placement-right-start {
    top: 0;

    .rs-form-error-message {
      top: 0;
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        top: @form-error-message-triangle-vertical;
      }
    }
  }

  &-placement-left-end,
  &-placement-right-end {
    bottom: 0;

    .rs-form-error-message {
      bottom: 0;
    }

    .rs-form-error-message-arrow {
      &::before,
      &::after {
        bottom: @form-error-message-triangle-vertical;
      }
    }
  }
}

/* rtl:end:ignore */
