@plugin '../plugins/palette';

// Highlight levels
@H050: #fffef2;
@H100: #fffbc2;
@H200: #fffa91;
@H300: #fffa61;
@H400: #fffc30;
@H500: #ffff00;
@H600: #f1f500;
@H700: #d9e000;
@H800: #b8c200;
@H900: #8f9900;

// Gray levels
@B000: #fff;
@B050: #e9ebf0;
@B100: #cbced4;
@B200: #a4a9b3;
@B300: #858b94;
@B400: #6a6f76;
@B500: #5c6066;
@B600: #3c3f43;
@B700: #292d33;
@B800: #1a1d24;
@B900: #0f131a;

// Secondary palette

@red-50: palette(@red-high-contrast, 50);
@red-100: palette(@red-high-contrast, 100);
@red-200: palette(@red-high-contrast, 200);
@red-300: palette(@red-high-contrast, 300);
@red-400: palette(@red-high-contrast, 400);
@red-500: @red-high-contrast;
@red-600: palette(@red-high-contrast, 600);
@red-700: palette(@red-high-contrast, 700);
@red-800: palette(@red-high-contrast, 800);
@red-900: palette(@red-high-contrast, 900);

@orange-50: palette(@orange-high-contrast, 50);
@orange-100: palette(@orange-high-contrast, 100);
@orange-200: palette(@orange-high-contrast, 200);
@orange-300: palette(@orange-high-contrast, 300);
@orange-400: palette(@orange-high-contrast, 400);
@orange-500: @orange-high-contrast;
@orange-600: palette(@orange-high-contrast, 600);
@orange-700: palette(@orange-high-contrast, 700);
@orange-800: palette(@orange-high-contrast, 800);
@orange-900: palette(@orange-high-contrast, 900);

@yellow-50: palette(@yellow-high-contrast, 50);
@yellow-100: palette(@yellow-high-contrast, 100);
@yellow-200: palette(@yellow-high-contrast, 200);
@yellow-300: palette(@yellow-high-contrast, 300);
@yellow-400: palette(@yellow-high-contrast, 400);
@yellow-500: @yellow-high-contrast;
@yellow-600: palette(@yellow-high-contrast, 600);
@yellow-700: palette(@yellow-high-contrast, 700);
@yellow-800: palette(@yellow-high-contrast, 800);
@yellow-900: palette(@yellow-high-contrast, 900);

@green-50: palette(@green-high-contrast, 50);
@green-100: palette(@green-high-contrast, 100);
@green-200: palette(@green-high-contrast, 200);
@green-300: palette(@green-high-contrast, 300);
@green-400: palette(@green-high-contrast, 400);
@green-500: @green-high-contrast;
@green-600: palette(@green-high-contrast, 600);
@green-700: palette(@green-high-contrast, 700);
@green-800: palette(@green-high-contrast, 800);
@green-900: palette(@green-high-contrast, 900);

@cyan-50: palette(@cyan-high-contrast, 50);
@cyan-100: palette(@cyan-high-contrast, 100);
@cyan-200: palette(@cyan-high-contrast, 200);
@cyan-300: palette(@cyan-high-contrast, 300);
@cyan-400: palette(@cyan-high-contrast, 400);
@cyan-500: @cyan-high-contrast;
@cyan-600: palette(@cyan-high-contrast, 600);
@cyan-700: palette(@cyan-high-contrast, 700);
@cyan-800: palette(@cyan-high-contrast, 800);
@cyan-900: palette(@cyan-high-contrast, 900);

@blue-50: palette(@blue-high-contrast, 50);
@blue-100: palette(@blue-high-contrast, 100);
@blue-200: palette(@blue-high-contrast, 200);
@blue-300: palette(@blue-high-contrast, 300);
@blue-400: palette(@blue-high-contrast, 400);
@blue-500: @blue-high-contrast;
@blue-600: palette(@blue-high-contrast, 600);
@blue-700: palette(@blue-high-contrast, 700);
@blue-800: palette(@blue-high-contrast, 800);
@blue-900: palette(@blue-high-contrast, 900);

@violet-50: palette(@violet-high-contrast, 50);
@violet-100: palette(@violet-high-contrast, 100);
@violet-200: palette(@violet-high-contrast, 200);
@violet-300: palette(@violet-high-contrast, 300);
@violet-400: palette(@violet-high-contrast, 400);
@violet-500: @violet-high-contrast;
@violet-600: palette(@violet-high-contrast, 600);
@violet-700: palette(@violet-high-contrast, 700);
@violet-800: palette(@violet-high-contrast, 800);
@violet-900: palette(@violet-high-contrast, 900);
