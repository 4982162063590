@import (reference) '../../Button/styles/index';

.rs-radio-group {
  display: flex;
  flex-direction: column;
}

.rs-radio-group-inline {
  flex-direction: row;
  margin-left: -1 * @radio-sense-width;
}

// Radio Group - picker appearance
.rs-radio-group-picker {
  display: inline-flex;
  color: var(--rs-text-primary);
  border: 1px solid var(--rs-border-primary);
  border-radius: @border-radius;
  margin-left: 0;

  .rs-radio-inline {
    margin-left: 0;
  }

  .rs-radio-checker {
    padding: 0;
    min-height: auto;
    border-bottom: 1px solid transparent;
    margin: 0 10px;
  }

  // Picker Radio group hidden radio.
  .rs-radio-wrapper {
    display: none;
  }

  .rs-radio-checker > label {
    // TODO: Should not inherit Button styles
    .rs-btn();
    .rs-btn-subtle();

    color: var(--rs-text-secondary);
    background: none;
    transition: color 0.3s linear;
    padding: (@padding-y - 1px) 0;

    .high-contrast-mode({
      transition: none;
    });

    &:hover,
    &:active {
      color: var(--rs-text-active);
      background: none;
    }
  }

  .rs-radio-checked .rs-radio-checker {
    border-bottom: 2px solid var(--rs-text-active);

    > label {
      color: var(--rs-text-active);
    }
  }

  .rs-radio-disabled .rs-radio-checker {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
    color: var(--rs-text-secondary);
  }
}
