@import '../../styles/common';
@import '../../Grid/styles/mixin';
@import '../../Grid/styles/grid-framework';

// Row
//
// Rows contain and clear the floats of your columns.
.rs-row {
  .make-row();
}
