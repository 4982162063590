@import '../../styles/common';
@import '../../Form/styles/mixin';

// Full container.
.rs-form-fluid .rs-form-control-wrapper {
  width: 100%;

  > .rs-input-number,
  > .rs-input {
    width: 100%;
  }
}

// Fixed width.
.rs-form-control-wrapper {
  // Used to provide relation for error message
  position: relative;

  > .rs-input-number,
  > .rs-input {
    width: @form-control-width;
  }

  &.read-only {
    pointer-events: none;
    touch-action: none;
  }
}

// Vertical forms
.rs-form-vertical .rs-form-group .rs-input-group {
  width: @form-control-width;
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  display: inline-block;
  // Make sure max width won't overflow.
  max-width: 100%;
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.rs-form-horizontal .rs-form-group {
  .rs-form-control-wrapper {
    float: left;
  }

  .rs-form-control-wrapper + .rs-form-help-text {
    clear: both;
  }

  // Help block
  .rs-form-control-wrapper + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    margin-left: @form-control-label-width + @form-control-label-margin-right;
  }
}

// Inline
.rs-form-inline .rs-form-group {
  .rs-form-control-wrapper {
    display: inline-block;
  }

  // Form control wrapper behind Screen only dom no need margin left.
  .rs-sr-only + .rs-form-control-wrapper {
    margin-left: 0;
  }
}
