@import '../../TagPicker/styles/index';

.rs-picker-disabled-options {
  .rs-picker-toggle {
    padding-right: @dropdown-toggle-padding-right !important;
  }
  .rs-picker-tag-wrapper {
    margin-right: @dropdown-toggle-padding-right !important;
  }
  .rs-picker-toggle-clean {
    right: (@dropdown-toggle-padding-right / 2 - 6) !important;
  }
}
