.notification-variant(@icon-color) {
  // Content
  .rs-notification-content {
    // Description
    // Has title and description need to ajust distance
    .rs-notification-title + .rs-notification-description {
      margin-left: @notify-title-icon-size+ @notify-title-icon-margin;
    }

    // Tip icon
    .rs-icon {
      color: @icon-color;

      &::before {
        vertical-align: middle;
      }
    }
  }
}
