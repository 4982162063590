@import '../../styles/common';
@import 'mixin';

.rs-avatar {
  background-color: var(--rs-avatar-bg);
  color: var(--rs-avatar-text);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .avatar-md();

  & > .rs-icon {
    vertical-align: middle;
  }

  &-image {
    &::before {
      content: attr(alt);
      position: absolute;
      width: 100%;
      height: inherit;
      top: 0;
      left: 0;
      background: var(--rs-avatar-bg);
      text-align: center;
      padding: 0 2px;
      .ellipsis-basic();
    }
  }

  &-circle {
    border-radius: 50%;
  }

  &-lg {
    .avatar-lg();
  }

  &-sm {
    .avatar-sm();
  }

  &-xs {
    .avatar-xs();
  }
}
