@import '../../styles/common';

//
// Panel
// --------------------------------------------------

.rs-panel {
  border-radius: 6px;
  overflow: hidden;

  &-bordered {
    border: 1px solid var(--rs-border-primary);
  }

  &-shaded {
    box-shadow: var(--rs-panel-shadow);
  }

  &-header,
  &-body {
    padding: 20px;
  }

  // When the width is set to full, reset the value of padding.
  &-body-fill {
    padding: 0 !important;
  }

  &-header {
    color: var(--rs-text-heading);
    font-size: 16px;
    line-height: unit((@line-height-computed / 16px));
  }

  &-title {
    margin: 0;

    a {
      color: inherit;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  // Adjust the padding value when the head and body are combined.
  &-header + &-collapse &-body,
  &-header + &-body {
    padding-top: 0;
  }

  // Collapsible panel header hover background
  &-collapsible > &-header {
    cursor: pointer;
    transition: background-color 0.3s linear, border-radius 0.3s linear;
    position: relative;

    > .rs-icon {
      color: var(--rs-text-secondary);
      position: absolute;
      top: 24px;
      right: 20px;
      transition: transform 0.3s linear;
    }
  }
}
