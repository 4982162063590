@import '../../styles/common';

//
// Steps
// --------------------------------------------------
.rs-steps {
  min-height: @steps-icon-diameter;
}

// Item
// --------------------------
.rs-steps-item {
  padding-left: @steps-icon-space-width;
  position: relative;
  overflow: hidden;
}

// Item icon
.rs-steps-item-icon-wrapper {
  width: @steps-icon-diameter;
  height: @steps-icon-diameter;
  text-align: center;
  font-size: @steps-icon-size;
  line-height: @steps-icon-line-height;
  color: var(--rs-text-secondary);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border: 1px solid var(--rs-steps-border);
  border-radius: 50%;

  // Finish status show check mark.
  .rs-steps-item-status-finish & {
    border-color: var(--rs-steps-state-finish);
    color: var(--rs-steps-state-finish);
  }

  // Error status show X mark.
  .rs-steps-item-status-error & {
    border-color: var(--rs-steps-state-error);
    color: var(--rs-steps-state-error);
  }

  .rs-steps-item-status-process & {
    border-color: var(--rs-steps-state-process);
    background-color: var(--rs-steps-state-process);
    color: var(--rs-text-inverse);

    .rs-icon {
      color: var(--rs-steps-icon-state-process);
    }
  }

  &.rs-steps-item-custom-icon {
    border: none;
    background: none;
  }

  // Icon content
  > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;

    .rs-icon {
      font-size: 14px;
      vertical-align: middle;
    }
  }
}

// Item content
// --------------------------
.rs-steps-item-content {
  color: var(--rs-text-secondary);
  display: inline-block;
  // Change static to relative, Make `z-index` more than Tail line
  position: relative;
  width: 100%;

  .rs-steps-item-status-process & {
    color: var(--rs-text-primary);
  }

  .rs-steps-item-status-error & {
    color: var(--rs-text-error);
  }
}

// Item title
.rs-steps-item-title {
  position: relative;
  font-size: @steps-title-font-size;
  line-height: @steps-title-line-height;
  min-height: @steps-title-font-size;
  padding-right: @steps-icon-spacing;
  display: inline-block;

  &:empty {
    padding-right: 0;
  }
}

// Item description
.rs-steps-item-description {
  margin-top: @steps-description-margin-top;
}

.rs-steps-item-tail,
.rs-steps-item-title::after {
  position: absolute;
  border-color: var(--rs-steps-border);

  .rs-steps-item-status-process & {
    border-color: var(--rs-steps-border);
  }

  .rs-steps-item-status-finish & {
    border-color: var(--rs-steps-state-finish);
  }

  .rs-steps-next-error & {
    border-color: var(--rs-steps-border-state-error);
  }
}

// Horizontal
.rs-steps-horizontal {
  display: flex;
  justify-content: space-between;

  .rs-steps-item {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: @steps-icon-diameter;

    // The last node is self-adaptive width.
    &:last-child {
      flex-grow: 0;
      flex-shrink: 0;

      // The last item hidden tail line
      .rs-steps-item-title::after {
        display: none;
      }
    }

    // There is a 10px spacing between each item.
    &:not(:first-child) {
      padding-left: (@steps-icon-space-width + @steps-item-spacing);

      .rs-steps-item-icon-wrapper {
        left: @steps-item-spacing;
      }
    }
  }

  .rs-steps-item-content {
    height: (@steps-title-font-size * @steps-title-line-height);
  }

  .rs-steps-item-title {
    &::after {
      content: '';
      top: (@steps-icon-diameter / 2);
      left: 100%;
      width: 9999px;
      border-top-width: 1px;
      border-top-style: solid;
    }
  }
}

// Verticla
.rs-steps-vertical {
  .rs-steps-item {
    padding-bottom: 20px;

    // There is a 10px spacing between each item.
    &:not(:first-child) {
      margin-top: @steps-item-spacing;
    }

    &:last-child .rs-steps-item-tail {
      display: none;
    }
  }

  .rs-steps-item-tail {
    top: @steps-icon-space-width;
    bottom: 0;
    left: (@steps-icon-diameter / 2);
    border-left-width: 1px;
    border-left-style: solid;
  }
}

// Small
// Resize: Diameter、font-size and so on.
.rs-steps-small {
  min-height: @small-steps-icon-diameter;

  &.rs-steps-horizontal .rs-steps-item {
    min-height: @small-steps-icon-diameter;
  }

  &.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: (@small-steps-icon-space-width + @steps-icon-spacing);
  }

  &.rs-steps-vertical .rs-steps-item-tail {
    left: (@small-steps-icon-diameter / 2);
  }

  &.rs-steps-horizontal .rs-steps-item-title::after {
    top: 12px;
  }

  .rs-steps-item {
    padding-left: @small-steps-icon-space-width;
  }

  .rs-steps-item-icon-wrapper {
    width: @small-steps-icon-diameter;
    height: @small-steps-icon-diameter;
    font-size: @small-steps-icon-size;
    line-height: @small-steps-icon-line-height;
  }

  .rs-steps-item-title {
    font-size: @small-steps-icon-size;
    line-height: @small-steps-title-line-height;
  }

  .rs-steps-item-description {
    margin-top: @small-steps-description-margin-top;
    font-size: @small-steps-description-font-size;
    line-height: @small-steps-description-line-height;
  }
}
