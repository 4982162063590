@import '../../styles/common';
@import '../../Button/styles/mixin';
@import 'mixin';

//
// Pickers
// --------------------------------------------------

// The switch trail
// todo: Consider renaming as .rs-toggle
.rs-toggle {
  position: relative;

  // Default size is middle.
  .toggle(md);
}

.rs-toggle-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.rs-toggle-presentation {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--rs-toggle-bg);
  color: var(--rs-toggle-thumb);
  cursor: pointer;
  transition: background-color @toggle-transition, width @toggle-transition;
  user-select: none;

  .high-contrast-mode({
    transition: none;
    box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
  });

  .rs-toggle-input:focus-visible + & {
    .focus-ring();

    .high-contrast-mode({
      box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);;
    });
  }

  &:hover {
    background-color: var(--rs-toggle-hover-bg);
  }

  // The switch thumb
  &::after {
    content: '';
    cursor: pointer;
    position: absolute;
    background-color: currentColor;
    transition: left @toggle-transition, margin-left @toggle-transition, width @toggle-transition;

    .high-contrast-mode({
      transition: none;
    });
  }

  // disabled state
  .rs-toggle-disabled & {
    background-color: var(--rs-toggle-disabled-bg);
    color: var(--rs-toggle-disabled-thumb);
    box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
    cursor: not-allowed;
  }

  // checked state
  .rs-toggle-checked & {
    background-color: var(--rs-toggle-checked-bg);
    color: var(--rs-toggle-checked-thumb);
    box-shadow: none;

    &:hover {
      background-color: var(--rs-toggle-checked-hover-bg);
    }
  }

  .rs-toggle-disabled.rs-toggle-checked & {
    background-color: var(--rs-toggle-checked-disabled-bg);
    color: var(--rs-toggle-checked-disabled-thumb);
  }

  .rs-toggle-loading & {
    &::after {
      display: none;
    }
  }
}

// Label text inside the switch
.rs-toggle-inner {
  display: block;
  transition: margin @toggle-transition;

  .high-contrast-mode({
    transition: none;
  });
}

.rs-toggle-loader {
  position: absolute;
  transition: left @toggle-transition, margin-left @toggle-transition, width @toggle-transition;

  .rs-loader-spin {
    &::before {
      border-color: var(--rs-toggle-loader-ring);
    }
    &::after {
      border-top-color: var(--rs-toggle-loader-rotor);
    }
  }
}

// small
.rs-toggle-sm {
  .toggle(sm);
}

// middle
.rs-toggle-md {
  .toggle(md);
}

// large
.rs-toggle-lg {
  .toggle(lg);
}
