@import '../../styles/common';
@import 'mixins';

//
// Tooltips
// --------------------------------------------------

// Base class
.rs-tooltip {
  position: absolute;
  z-index: @zindex-tooltip;
  display: block;
  font-size: @font-size-small;
  opacity: 0;
  line-height: @line-height-small;
  max-width: @tooltip-max-width;
  padding: 2px 10px;
  color: var(--rs-tooltip-text);
  text-align: center; // TODO: remove align center?
  background-color: var(--rs-tooltip-bg);
  border-radius: @tooltip-border-radius;
  overflow-wrap: break-word;

  &.rs-anim-fade {
    transition: opacity 0.1s linear, transform 0.1s ease-out;
  }

  &.rs-anim-in {
    opacity: 1;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
  }

  // Arrows
  &::before,
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &::before {
    display: none;
  }

  .high-contrast-mode({
    border: 1px solid var(--rs-border-primary);

    &::before {
      display: block;
    }
  });
}

// Common style for .placement-top*
// TODO consider replacing class with data-placement attribute
.rs-tooltip[class*='placement-top'] {
  margin-top: -(@tooltip-arrow-width + 2px);
  .set-translate-transition(0, -@tooltip-translate-distance);

  .with-tooltip-arrow(bottom);
}

// Common style for .placement-bottom*
.rs-tooltip[class*='placement-bottom'] {
  margin-top: (@tooltip-arrow-width + 2px);
  .set-translate-transition(0, @tooltip-translate-distance);

  .with-tooltip-arrow(top);
}

/* rtl:begin:ignore */
// Common style for .placement-right*
.rs-tooltip[class*='placement-right'] {
  margin-left: (@tooltip-arrow-width + 2px);
  .set-translate-transition(@tooltip-translate-distance, 0);

  .with-tooltip-arrow(left);
}

// Common style for .placement-lef*
.rs-tooltip[class*='placement-left'] {
  margin-left: -(@tooltip-arrow-width + 2px);
  .set-translate-transition(-@tooltip-translate-distance, 0);

  .with-tooltip-arrow(right);
}

/* rtl:end:ignore */

.rs-tooltip.placement-bottom,
.rs-tooltip.placement-top {
  &::before,
  &::after {
    left: 50%;
  }
}

.rs-tooltip.placement-bottom-start,
.rs-tooltip.placement-top-start {
  &::before,
  &::after {
    left: @tooltip-arrow-gap-lg;
  }
}

.rs-tooltip.placement-bottom-end,
.rs-tooltip.placement-top-end {
  &::before {
    right: @tooltip-arrow-gap-sm - 1px;
  }

  &::after {
    right: @tooltip-arrow-gap-sm;
  }
}

.rs-tooltip.placement-right,
.rs-tooltip.placement-left {
  &::before,
  &::after {
    top: 50%;
  }
}

.rs-tooltip.placement-right-start,
.rs-tooltip.placement-left-start {
  &::before,
  &::after {
    top: @tooltip-arrow-gap-lg;
  }
}

.rs-tooltip.placement-right-end,
.rs-tooltip.placement-left-end {
  &::before {
    bottom: @tooltip-arrow-gap-sm - 1px;
  }
  &::after {
    bottom: @tooltip-arrow-gap-sm;
  }
}
