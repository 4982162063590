@import '../../styles/common';
@import '../../Input/styles/index';
@import '../../InputGroup/styles/index';
@import 'mixin';

.rs-input-number {
  background-color: var(--rs-input-bg);

  &-btn-group-vertical {
    position: relative;
    width: 20px;
    flex: 0 0 20px;
    display: block;

    & > .rs-btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      position: relative;
      border: none;
    }
  }

  &-touchspin-up,
  &-touchspin-down {
    position: relative;
    border-radius: 0;
    padding: 0;
    box-shadow: none !important;
    // Default size.
    .input-touchspin(base);

    //sizes
    .rs-input-group-lg & {
      .input-touchspin(large);
    }

    .rs-input-group-sm & {
      .input-touchspin(small);
    }

    .rs-input-group-xs & {
      .input-touchspin(extra-small);
    }

    > .rs-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: @number-input-touchspin-font-size;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-btn-group-vertical:last-child &-touchspin-up {
    border-top-right-radius: @border-radius;
  }

  &-btn-group-vertical:last-child &-touchspin-down {
    border-bottom-right-radius: @border-radius;
  }

  > .rs-input {
    .border-left-radius(@border-radius) !important;

    &:disabled + .rs-input-number-btn-group-vertical {
      .rs-input-number-touchspin-up,
      .rs-input-number-touchspin-down {
        background-color: var(--rs-input-disabled-bg);
      }
    }
  }

  // Reset Input Number styling
  // Reference https://stackoverflow.com/questions/26024771/styling-a-input-type-number#answer-40189766
  input[type='number'] {
    appearance: textfield;

    &::-webkit-inner-spin-button {
      /* stylelint-disable */
      -webkit-appearance: none;
    }
  }
}
