@keyframes errorMessageSlideUpIn {
  0% {
    transform: translate3d(0, @form-error-message-translate-distance, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideDownIn {
  0% {
    transform: translate3d(0, -1 * @form-error-message-translate-distance, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideLeftIn {
  0% {
    transform: translate3d(-1 * @form-error-message-translate-distance, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideRightIn {
  0% {
    transform: translate3d(@form-error-message-translate-distance, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
