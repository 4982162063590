@import '../../styles/common';
@import '../../styles/mixins/listbox';
@import '../../Button/styles/mixin';
@import '../../Picker/styles/mixin';

// DatePicker Picker
// ----------------------

// Change the <caret/> & <clean/> vertical position make it align middle.
.rs-picker-date,
.rs-picker-daterange {
  &.rs-picker-default {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: @padding-y - @picker-default-toggle-border-width;
    }
  }
}

// Toolbar
.rs-picker-toolbar {
  .clearfix();

  padding: @calendar-picker-padding;
  border-top: 1px solid @calendar-toolbar-border-color;

  &-ranges {
    display: inline-block;
  }

  &-right {
    float: right;
  }
}

// Picker date
.rs-picker-date {
  &-inline {
    height: 299px;

    .rs-calendar {
      height: 286px;
    }
  }
}

// Calendar in DatePicker popup
.rs-picker-menu {
  @calendar-width: (
    @datepicker-calendar-cell-gap-x * 6 + 30px * 7 + @datepicker-calendar-padding-x * 2
  );

  .rs-picker-toolbar {
    max-width: 100%;
  }

  .rs-calendar {
    min-width: @calendar-width;
    // To make sure <Calendar/> horizontal align at center when toolbar  width is so long.
    display: block;
    margin: 0 auto;

    &-show-week-numbers {
      @calendar-width: (
        @datepicker-calendar-cell-gap-x * 7 + 30px * 8 + @datepicker-calendar-padding-x * 2
      );

      min-width: @calendar-width;
    }

    &-header {
      width: 100%;
    }

    &-table {
      width: unset;
    }

    .rs-calendar-month-dropdown-cell-content,
    .rs-calendar-table-cell-content {
      width: @calendar-in-menu-content-side-length;
      height: @calendar-in-menu-content-side-length;
    }

    .rs-calendar-month-dropdown-cell-content:hover {
      .listbox-option-active();
    }

    .rs-calendar-table-header-row .rs-calendar-table-cell-content {
      height: 24px;
      padding-top: 0;
    }

    .rs-calendar-table-cell-content {
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
    }

    // There's a small gap between cell and cell-content (cell-content smaller than cell)
    // So don't apply hover style directly on cell-content
    .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
      // borrowed from listbox option
      .listbox-option-active();
    }

    .rs-calendar-month-dropdown-scroll {
      height: 227px;
    }

    .rs-calendar-time-dropdown-column > ul {
      height: 214px;
      padding-bottom: 184px;
    }
  }
}
