@import '../../styles/common';
@import '../../Button/styles/index';

//
// IconButton
// --------------------------------------------------
.rs-btn-icon {
  > .rs-icon {
    vertical-align: bottom;
  }
}

// Icon Button with text
.btn-icon-with-text(@bg) {
  > .rs-icon {
    background-color: @bg;
  }
}

// Only support default/primary button
.rs-btn-icon-with-text {
  > .rs-icon {
    position: absolute;
    top: 0;
    display: block;
    background-color: var(--rs-iconbtn-addon);
    transition: @btn-transition;
  }

  .button-activated({
    .btn-icon-with-text(var(--rs-iconbtn-activated-addon));
  });

  .button-pressed({
    .btn-icon-with-text(var(--rs-iconbtn-pressed-addon));
  });

  .button-disabled({
    .btn-icon-with-text(var(--rs-iconbtn-addon));
  });
}

.rs-btn-icon-with-text.rs-btn-primary {
  .btn-icon-with-text(var(--rs-iconbtn-primary-addon));

  .button-activated({
    .btn-icon-with-text(var(--rs-iconbtn-primary-activated-addon));
  });

  .button-pressed({
    .btn-icon-with-text(var(--rs-iconbtn-primary-pressed-addon));
  });

  .button-disabled({
    .btn-icon-with-text(var(--rs-iconbtn-primary-addon));
  });
}

// Circle Button
.rs-btn-icon-circle {
  border-radius: 50% !important;
}
