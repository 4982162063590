@import '../../styles/common';
@import 'animation';

.rs-progress {
  // Remove whitespace.
  &-info {
    font-size: 0;
  }

  // Set base font-size && reset font-size
  &,
  &-icon-success,
  &-icon-fail,
  &-info-status {
    font-size: @progress-font-size;
    line-height: unit((@line-height-computed / @progress-font-size));
  }
}

.rs-progress-circle {
  position: relative;
  font-size: 0;

  &-info {
    @info-height: 36px;

    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: @progress-font-size;
    line-height: unit((@info-height / @progress-font-size));
    height: @info-height;
    color: var(--rs-text-secondary);
  }

  .rs-progress-trail {
    stroke: var(--rs-progress-bg);
  }

  .rs-progress-stroke {
    stroke: var(--rs-progress-bar);
    transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  }

  // Setting status color
  &-success &-info {
    color: var(--rs-progress-bar-success);
  }

  &-success .rs-progress-stroke {
    stroke: var(--rs-progress-bar-success);
  }

  &-fail &-info {
    color: var(--rs-progress-bar-fail);
  }

  &-fail .rs-progress-stroke {
    stroke: var(--rs-progress-bar-fail);
  }
}

.rs-progress-line {
  display: flex;
  align-items: center;
  width: 100%;
  padding: @progress-line-padding-vertical @progress-line-padding-horizontal;

  &-active &-bg {
    position: relative;

    &::before {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border-radius: 10px;
      animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    }
  }

  .rs-progress-info {
    width: 50px;
    flex: 0 0 50px;
    height: 20px;
    padding-left: @progress-element-gap;
    display: flex;
    align-items: center;
  }

  &-outer {
    flex: 1 1 auto;
    border-radius: (@progress-bar-height / 2);
    overflow: hidden;
    // Make sure outer vertical-align:center;
    vertical-align: middle;
  }

  &-inner {
    background: var(--rs-progress-bg);
    border-radius: (@progress-bar-height / 2);
  }

  &-bg {
    background-color: var(--rs-progress-bar);
    border-radius: (@progress-bar-height / 2);
    transition: @progress-bg-transition;
    transition-property: width, background-color;
  }

  &-inner,
  &-bg {
    height: @progress-bar-height;
  }

  // Setting status color
  &-success {
    color: var(--rs-progress-bar-success);
  }

  &-success &-bg {
    background-color: var(--rs-progress-bar-success);
  }

  &-fail {
    color: var(--rs-progress-bar-fail);
  }

  &-fail &-bg {
    background-color: var(--rs-progress-bar-fail);
  }

  &&-vertical {
    flex-direction: column-reverse;
    width: auto;
  }

  &&-vertical &-outer {
    width: auto;
    flex: 1 1 auto;
  }

  &&-vertical &-inner {
    min-height: 100px;
    width: @progress-bar-height;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    height: 100%;
  }

  &&-vertical .rs-progress-info {
    flex-basis: auto;
    padding-left: 0;
    width: auto;
  }

  &&-vertical &-bg {
    width: 100%;
    transition-property: height, background-color;
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
  }

  &&-vertical&-active &-bg::before {
    animation-name: progress-active-vertical;
  }
}

// Status icons styles
.rs-progress-icon-success,
.rs-progress-icon-fail {
  display: inline-block;

  svg {
    // x2= 1px
    .icon-bolder(0.5px);
  }

  .rs-progress-line & {
    line-height: 0;
    font-size: 14px;
  }

  .rs-progress-circle & {
    line-height: 0;
    font-size: 36px;
  }
}
