.drawer-content-width(@width) {
  width: @width;
}

.drawer-content-height(@height) {
  height: @height;
}

.drawer-animation(@timing) {
  animation-duration: 0.3s;
  animation-timing-function: @timing;
  animation-fill-mode: forwards;
}

.drawer-animation-setting(@move-in-animation-name,@move-out-animation-name) {
  &.rs-slide-out {
    animation-name: @move-out-animation-name;
  }

  &.rs-slide-in {
    animation-name: @move-in-animation-name;
  }
}
