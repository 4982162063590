@import 'color-modes';

// "-active" for `aria-activedescendant`
.menuitem-active() {
  background-color: var(--rs-menuitem-active-bg);
  color: var(--rs-menuitem-active-text);

  .high-contrast-mode({
    text-decoration: underline;
    box-shadow: inset 0 0 0 2px #fff;
  });
}
