
@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  100% {
    opacity: 0;
    height: 100%;
  }
}
