@import '../../styles/common';
@import '../../Checkbox/styles/index';
@import '../../Picker/styles/index';

// Check Picker
// ----------------------

// Menu Items
.rs-picker-check-menu-items {
  .picker-menu-items-reset();
  .picker-menu-group-common(picker);
  .picker-menu-group-title(picker);
  .picker-menu-group-closed(picker);

  .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    .picker-item-active();
  }
}
